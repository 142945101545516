import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Informe Anual y Sustentable"/>
            <Banner title="Informe Anual y Sustentable"/>
            <section className="welcome_section_area propuesta">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">IRStrat ofrece todo el portafolio de servicios necesarios para la producción
                            de un
                            robusto y exitoso informe anual y de sustentabilidad, capaz de transmitir de manera gráfica,
                            interactiva y
                            textual el mensaje de su empresa a inversionistas y stakeholders.</p>
                        <p className="f_16">Oferta de valor: Contamos con una gran ventaja sobre nuestros competidores,
                            que mayormente se enfocan a
                            diseño gráfico, la cual se deriva de las sinergias que obtenemos de nuestro “negocio
                            central” de
                            consultoría de relaciones con inversionistas y lobbying financiero. Tenemos la capacidad de
                            poner a su
                            disposición a un equipo senior de especialistas en comunicaciones financieras y
                            sustentabilidad, peritos
                            traductores y editores de alto nivel, además de nuestro staff de diseñadores y
                            programadores.</p>
                    </div>
                    <div className="row servicios service_right_sidebar p-0 mb-5">
                        <div className="col-lg-12">
                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse1">
                                                <span className="pluse">+</span><span className="minus">-</span>Desarrollo
                                                Gráfico
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse1" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body">
                                    

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Concepto general y gráfico del
                                                    informe
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                    portafolio-propuestas de diseño
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Optimización de diseño</li>
                                                <li><i className="fa fa-angle-right"></i>Producción gráfica (español e
                                                    inglés)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Supervisión de impresión</li>
                                                <li><i className="fa fa-angle-right"></i>Desarrollo versión descargable
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse2"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse2">
                                                <span className="pluse">+</span><span className="minus">-</span>Desarrollo
                                                Web</a>
                                        </h4>
                                    </div>
                                    <div id="collapse2" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Desarrollo de arquitectura del
                                                    micrositio
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Desarrollo de interfase gráfica
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Desarrollo de la página
                                                    principal y centro de descarga
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Programación de contenido
                                                    (español e inglés)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse3"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Contenido-español</a>
                                        </h4>
                                    </div>
                                    <div id="collapse3" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Continuidad con informes
                                                    anteriores
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Logística de producción</li>
                                                <li><i className="fa fa-angle-right"></i>Recolección de información</li>
                                                <li><i className="fa fa-angle-right"></i>Estructuración</li>
                                                <li><i className="fa fa-angle-right"></i>Redacción de contenido</li>
                                                <li><i className="fa fa-angle-right"></i>Actualización de sección de
                                                    sustentabilidad
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse4"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span
                                                className="minus">-</span> Contenido-inglés</a>
                                        </h4>
                                    </div>
                                    <div id="collapse4" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Continuidad de estilo</li>
                                                <li><i className="fa fa-angle-right"></i>Traducción total</li>
                                                <li><i className="fa fa-angle-right"></i>Circle-up de director y
                                                    analista de IRStrat
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Circle-up de perito traductor
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Limpieza de errores
                                                    ortográficos y correcciones de estilo
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse5"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Paquete
                                                de sustentabilidad</a>
                                        </h4>
                                    </div>
                                    <div id="collapse5" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Definición de aspectos
                                                    materiales
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Delimitación de áreas de
                                                    impacto
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Definición de indicadores</li>
                                                <li><i className="fa fa-angle-right"></i>Definición de memoria y su
                                                    profundidad
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Cumplimiento con estándares GRI
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
}

export default Contenido
